.l-footer {
  font-size: 12px;
  padding: 40px 0;
  text-align: center;
  color: gray;
  a {
    color: #75aadb;
  }
  p {
    margin-bottom: 10px;
  }
}