.l-header {
  margin: 0 auto;
  text-align: center;
  padding: 40px 0;
  h1 {
    line-height: 0;
    width: 240px;
    margin: 0 auto;
  }
}
