@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul, li {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* iOSでのデフォルトスタイルをリセット */
input[type="submit"],
input[type="button"] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
  cursor: pointer; }

input[type="submit"]::-webkit-search-decoration,
input[type="button"]::-webkit-search-decoration {
  display: none; }

input[type="submit"]::focus,
input[type="button"]::focus {
  outline-offset: -2px; }

body {
  font-family: "Hiragino Kaku Gothic ProN","メイリオ", sans-serif; }

img {
  max-width: 100%;
  max-height: 100%; }

.l-wrap {
  width: 640px;
  max-width: 100%;
  margin: 0 auto; }

input.text {
  border: 1px solid #75aadb;
  width: calc(100% - 30px);
  padding: 5px;
  margin: 0 15px;
  box-sizing: border-box;
  font-size: 16px; }

.l-img {
  text-align: center;
  margin: 0 0 20px 0; }
  .l-img p {
    margin: 20px 0; }
  .l-img a {
    color: #75aadb; }

.l-controller {
  padding: 0 20px; }

.button-prev, .button-next, .button-restart, .button-img .submit, .button-start, .button-fb, .button-tw {
  display: block;
  width: 100%;
  position: relative;
  text-align: center;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  text-align: center;
  padding: 30px 0;
  font-size: 16px;
  border-radius: 5px;
  margin: 0 0 10px 0;
  line-height: 1.4; }
  .button-prev:before, .button-next:before, .button-restart:before, .button-img .submit:before, .button-start:before, .button-fb:before, .button-tw:before {
    position: absolute;
    top: 50%;
    font-size: 14px;
    transform: translate3D(0, -50%, 0); }
  .is-disabled.button-prev, .is-disabled.button-next, .is-disabled.button-restart, .button-img .is-disabled.submit, .is-disabled.button-start, .is-disabled.button-fb, .is-disabled.button-tw {
    pointer-events: none;
    background: silver; }
  .is-hide.button-prev, .is-hide.button-next, .is-hide.button-restart, .button-img .is-hide.submit, .is-hide.button-start, .is-hide.button-fb, .is-hide.button-tw {
    display: none; }

.button-prev:before {
  content: "◀︎";
  left: 20px; }

.button-next:before, .button-img .submit:before, .button-start:before {
  content: "▶︎";
  right: 20px; }

.button-prev {
  background: gray; }

.button-next {
  background: #75aadb; }

.button-restart {
  background: gray;
  font-size: 13px; }

.button-img .submit {
  width: 100%;
  background: #75aadb;
  font-size: 13px; }

.button-img.is-hide {
  display: none; }

.button-start {
  background: #75aadb; }

.l-controller-sns {
  display: flex;
  padding: 0 10px; }

.button-fb {
  width: 100%;
  background: #4267b2;
  margin: 0 5px; }

.button-tw {
  width: 100%;
  background: #1b95e0;
  margin: 0 5px; }

.field .g, .field .d, .field .m, .field .f {
  position: absolute;
  width: 15px;
  height: 15px;
  background-size: 15px auto;
  background-position: 0 0;
  background-repeat: no-repeat; }

.field .g.is-current, .field .d.is-current, .field .m.is-current, .field .f.is-current {
  height: 35px; }
  .field .g.is-current:after, .field .d.is-current:after, .field .m.is-current:after, .field .f.is-current:after {
    content: "";
    display: block;
    width: 100%;
    height: 20px;
    padding-top: 15px;
    background-image: url(../img/formation/items/arrow.png);
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 15px auto; }

.field .p_23, .field .p_1, .field .p_12, .field .p_14, .field .p_3, .field .p_8, .field .p_18, .field .p_4, .field .p_6, .field .p_16, .field .p_17, .field .p_2, .field .p_13, .field .p_22, .field .p_5, .field .p_15, .field .p_7, .field .p_20, .field .p_11, .field .p_10, .field .p_19, .field .p_21, .field .p_9, .field .p_else {
  width: 60px;
  height: 23px;
  background-size: 60px auto; }

.field {
  position: relative;
  width: 300px;
  height: 200px;
  margin: 0 auto;
  background-image: url(../img/formation/items/field.jpg);
  background-size: 300px auto;
  background-repeat: no-repeat; }
  .field .g {
    background-image: url(../img/formation/items/g.png);
    top: 94px;
    left: 20px; }
    .field .g.is-current {
      background-image: url(../img/formation/items/g_current.png); }
  .field .d {
    background-image: url(../img/formation/items/d.png); }
    .field .d.is-current {
      background-image: url(../img/formation/items/d_current.png); }
  .field .m {
    background-image: url(../img/formation/items/m.png); }
    .field .m.is-current {
      background-image: url(../img/formation/items/m_current.png); }
  .field .f {
    background-image: url(../img/formation/items/f_current.png); }
    .field .f.is-current {
      background-image: url(../img/formation/items/f_current.png); }
  .field .p_23 {
    background-image: url(../img/player/23.png) !important; }
    .field .p_23.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .p_23.d {
      transform: translate3d(-10px, -5px, 0); }
    .field .p_23.m {
      transform: translate3d(-5px, -5px, 0); }
    .field .p_23.f {
      transform: translate3d(-10px, -5px, 0); }
  .field .p_1 {
    background-image: url(../img/player/1.png) !important; }
    .field .p_1.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .p_1.d {
      transform: translate3d(-10px, -5px, 0); }
    .field .p_1.m {
      transform: translate3d(-5px, -5px, 0); }
    .field .p_1.f {
      transform: translate3d(-10px, -5px, 0); }
  .field .p_12 {
    background-image: url(../img/player/12.png) !important; }
    .field .p_12.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .p_12.d {
      transform: translate3d(-10px, -5px, 0); }
    .field .p_12.m {
      transform: translate3d(-5px, -5px, 0); }
    .field .p_12.f {
      transform: translate3d(-10px, -5px, 0); }
  .field .p_14 {
    background-image: url(../img/player/14.png) !important; }
    .field .p_14.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .p_14.d {
      transform: translate3d(-10px, -5px, 0); }
    .field .p_14.m {
      transform: translate3d(-5px, -5px, 0); }
    .field .p_14.f {
      transform: translate3d(-10px, -5px, 0); }
  .field .p_3 {
    background-image: url(../img/player/3.png) !important; }
    .field .p_3.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .p_3.d {
      transform: translate3d(-10px, -5px, 0); }
    .field .p_3.m {
      transform: translate3d(-5px, -5px, 0); }
    .field .p_3.f {
      transform: translate3d(-10px, -5px, 0); }
  .field .p_8 {
    background-image: url(../img/player/8.png) !important; }
    .field .p_8.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .p_8.d {
      transform: translate3d(-10px, -5px, 0); }
    .field .p_8.m {
      transform: translate3d(-5px, -5px, 0); }
    .field .p_8.f {
      transform: translate3d(-10px, -5px, 0); }
  .field .p_18 {
    background-image: url(../img/player/18.png) !important; }
    .field .p_18.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .p_18.d {
      transform: translate3d(-10px, -5px, 0); }
    .field .p_18.m {
      transform: translate3d(-5px, -5px, 0); }
    .field .p_18.f {
      transform: translate3d(-10px, -5px, 0); }
  .field .p_4 {
    background-image: url(../img/player/4.png) !important; }
    .field .p_4.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .p_4.d {
      transform: translate3d(-10px, -5px, 0); }
    .field .p_4.m {
      transform: translate3d(-5px, -5px, 0); }
    .field .p_4.f {
      transform: translate3d(-10px, -5px, 0); }
  .field .p_6 {
    background-image: url(../img/player/6.png) !important; }
    .field .p_6.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .p_6.d {
      transform: translate3d(-10px, -5px, 0); }
    .field .p_6.m {
      transform: translate3d(-5px, -5px, 0); }
    .field .p_6.f {
      transform: translate3d(-10px, -5px, 0); }
  .field .p_16 {
    background-image: url(../img/player/16.png) !important; }
    .field .p_16.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .p_16.d {
      transform: translate3d(-10px, -5px, 0); }
    .field .p_16.m {
      transform: translate3d(-5px, -5px, 0); }
    .field .p_16.f {
      transform: translate3d(-10px, -5px, 0); }
  .field .p_17 {
    background-image: url(../img/player/17.png) !important; }
    .field .p_17.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .p_17.d {
      transform: translate3d(-10px, -5px, 0); }
    .field .p_17.m {
      transform: translate3d(-5px, -5px, 0); }
    .field .p_17.f {
      transform: translate3d(-10px, -5px, 0); }
  .field .p_2 {
    background-image: url(../img/player/2.png) !important; }
    .field .p_2.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .p_2.d {
      transform: translate3d(-10px, -5px, 0); }
    .field .p_2.m {
      transform: translate3d(-5px, -5px, 0); }
    .field .p_2.f {
      transform: translate3d(-10px, -5px, 0); }
  .field .p_13 {
    background-image: url(../img/player/13.png) !important; }
    .field .p_13.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .p_13.d {
      transform: translate3d(-10px, -5px, 0); }
    .field .p_13.m {
      transform: translate3d(-5px, -5px, 0); }
    .field .p_13.f {
      transform: translate3d(-10px, -5px, 0); }
  .field .p_22 {
    background-image: url(../img/player/22.png) !important; }
    .field .p_22.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .p_22.d {
      transform: translate3d(-10px, -5px, 0); }
    .field .p_22.m {
      transform: translate3d(-5px, -5px, 0); }
    .field .p_22.f {
      transform: translate3d(-10px, -5px, 0); }
  .field .p_5 {
    background-image: url(../img/player/5.png) !important; }
    .field .p_5.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .p_5.d {
      transform: translate3d(-10px, -5px, 0); }
    .field .p_5.m {
      transform: translate3d(-5px, -5px, 0); }
    .field .p_5.f {
      transform: translate3d(-10px, -5px, 0); }
  .field .p_15 {
    background-image: url(../img/player/15.png) !important; }
    .field .p_15.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .p_15.d {
      transform: translate3d(-10px, -5px, 0); }
    .field .p_15.m {
      transform: translate3d(-5px, -5px, 0); }
    .field .p_15.f {
      transform: translate3d(-10px, -5px, 0); }
  .field .p_7 {
    background-image: url(../img/player/7.png) !important; }
    .field .p_7.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .p_7.d {
      transform: translate3d(-10px, -5px, 0); }
    .field .p_7.m {
      transform: translate3d(-5px, -5px, 0); }
    .field .p_7.f {
      transform: translate3d(-10px, -5px, 0); }
  .field .p_20 {
    background-image: url(../img/player/20.png) !important; }
    .field .p_20.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .p_20.d {
      transform: translate3d(-10px, -5px, 0); }
    .field .p_20.m {
      transform: translate3d(-5px, -5px, 0); }
    .field .p_20.f {
      transform: translate3d(-10px, -5px, 0); }
  .field .p_11 {
    background-image: url(../img/player/11.png) !important; }
    .field .p_11.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .p_11.d {
      transform: translate3d(-10px, -5px, 0); }
    .field .p_11.m {
      transform: translate3d(-5px, -5px, 0); }
    .field .p_11.f {
      transform: translate3d(-10px, -5px, 0); }
  .field .p_10 {
    background-image: url(../img/player/10.png) !important; }
    .field .p_10.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .p_10.d {
      transform: translate3d(-10px, -5px, 0); }
    .field .p_10.m {
      transform: translate3d(-5px, -5px, 0); }
    .field .p_10.f {
      transform: translate3d(-10px, -5px, 0); }
  .field .p_19 {
    background-image: url(../img/player/19.png) !important; }
    .field .p_19.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .p_19.d {
      transform: translate3d(-10px, -5px, 0); }
    .field .p_19.m {
      transform: translate3d(-5px, -5px, 0); }
    .field .p_19.f {
      transform: translate3d(-10px, -5px, 0); }
  .field .p_21 {
    background-image: url(../img/player/21.png) !important; }
    .field .p_21.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .p_21.d {
      transform: translate3d(-10px, -5px, 0); }
    .field .p_21.m {
      transform: translate3d(-5px, -5px, 0); }
    .field .p_21.f {
      transform: translate3d(-10px, -5px, 0); }
  .field .p_9 {
    background-image: url(../img/player/9.png) !important; }
    .field .p_9.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .p_9.d {
      transform: translate3d(-10px, -5px, 0); }
    .field .p_9.m {
      transform: translate3d(-5px, -5px, 0); }
    .field .p_9.f {
      transform: translate3d(-10px, -5px, 0); }
  .field .p_else {
    background-image: url(../img/player/else.png) !important; }
    .field .p_else.g {
      transform: translate3d(-25px, -5px, 0); }
    .field .p_else.d {
      transform: translate3d(-10px, -5px, 0); }
    .field .p_else.m {
      transform: translate3d(-5px, -5px, 0); }
    .field .p_else.f {
      transform: translate3d(-10px, -5px, 0); }
  .section-343 .field .d1 {
    top: 43px;
    left: 58px; }
  .section-343 .field .d2 {
    top: 94px;
    left: 58px; }
  .section-343 .field .d3 {
    top: 145px;
    left: 58px; }
  .section-343 .field .m1 {
    top: 18px;
    left: 133px; }
  .section-343 .field .m2 {
    top: 67px;
    left: 110px; }
  .section-343 .field .m3 {
    top: 126px;
    left: 110px; }
  .section-343 .field .m4 {
    top: 172px;
    left: 133px; }
  .section-343 .field .f1 {
    top: 27px;
    left: 207px; }
  .section-343 .field .f2 {
    top: 94px;
    left: 225px; }
  .section-343 .field .f3 {
    top: 157px;
    left: 207px; }
  .section-433 .field .d1 {
    top: 24px;
    left: 70px; }
  .section-433 .field .d2 {
    top: 76px;
    left: 63px; }
  .section-433 .field .d3 {
    top: 112px;
    left: 63px; }
  .section-433 .field .d4 {
    top: 163px;
    left: 70px; }
  .section-433 .field .m1 {
    top: 34px;
    left: 135px; }
  .section-433 .field .m2 {
    top: 94px;
    left: 117px; }
  .section-433 .field .m3 {
    top: 147px;
    left: 135px; }
  .section-433 .field .f1 {
    top: 36px;
    left: 212px; }
  .section-433 .field .f2 {
    top: 94px;
    left: 187px; }
  .section-433 .field .f3 {
    top: 147px;
    left: 212px; }
  .section-3322 .field .d1 {
    top: 42px;
    left: 62px; }
  .section-3322 .field .d2 {
    top: 95px;
    left: 62px; }
  .section-3322 .field .d3 {
    top: 145px;
    left: 62px; }
  .section-3322 .field .m1 {
    top: 20px;
    left: 124px; }
  .section-3322 .field .m2 {
    top: 94px;
    left: 124px; }
  .section-3322 .field .m3 {
    top: 163px;
    left: 124px; }
  .section-3322 .field .m4 {
    top: 50px;
    left: 157px; }
  .section-3322 .field .m5 {
    top: 138px;
    left: 157px; }
  .section-3322 .field .f1 {
    top: 78px;
    left: 202px; }
  .section-3322 .field .f2 {
    top: 113px;
    left: 202px; }

.l-footer {
  font-size: 12px;
  padding: 40px 0;
  text-align: center;
  color: gray; }
  .l-footer a {
    color: #75aadb; }
  .l-footer p {
    margin-bottom: 10px; }

.list-formation {
  display: flex;
  margin: 20px 0; }
  .list-formation li {
    margin: 0 10px; }
    .list-formation li a {
      display: block;
      text-decoration: none;
      color: #000;
      font-size: 13px; }
      .list-formation li a img {
        border: 3px solid transparent;
        box-sizing: border-box; }
      .list-formation li a.is-selected img {
        border: 3px solid #75aadb; }
    .list-formation li span {
      display: block;
      margin: 0 0 15px 0;
      text-align: center;
      font-weight: bold; }

.l-header {
  margin: 0 auto;
  text-align: center;
  padding: 40px 0; }
  .l-header h1 {
    line-height: 0;
    width: 240px;
    margin: 0 auto; }

.section-heading {
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 15px; }

.section-343,
.section-433,
.section-3322 {
  display: none; }

.section-inner {
  margin-bottom: 20px; }

.select {
  width: calc(100% - 30px);
  height: 40px;
  border: 1px solid #75aadb;
  color: #75aadb;
  font-size: 20px;
  font-weight: bold;
  margin: 15px; }

#share ul {
  padding: 20px 0;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center; }

#share li {
  font-size: 14px;
  text-align: center;
  width: 84px;
  margin-right: 1%;
  margin-bottom: 8px;
  border-radius: 3px;
  list-style: none; }

#share li.share-twitter {
  background: #55acee;
  box-shadow: 0 2px #2795e9; }

#share li.share-twitter:hover {
  background: #83c3f3; }

#share li.share-facebook {
  background: #3b5998;
  box-shadow: 0 2px #2d4373; }

#share li.share-facebook:hover {
  background: #4c70ba; }

#share li.share-google {
  background: #dd4b39;
  box-shadow: 0 2px #c23321; }

#share li.share-google:hover {
  background: #e47365; }

#share li.share-hatena {
  background: #2c6ebd;
  box-shadow: 0 2px #225694; }

#share li.share-hatena:hover {
  background: #4888d4; }

#share li.share-pocket {
  background: #f13d53;
  box-shadow: 0 2px #e6152f; }

#share li.share-pocket:hover {
  background: #f26f7f; }

#share li.share-line {
  background: #00C300;
  box-shadow: 0 2px #009d00; }

#share li.share-line:hover {
  background: #2bd92b; }

#share a {
  display: block;
  height: 27px;
  padding-top: 6px;
  color: #ffffff;
  text-decoration: none; }

#share a:hover {
  text-decoration: none;
  color: #ffffff; }
