.l-section {
}

.section-heading {
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 15px;
}

.section-343,
.section-433,
.section-3322 {
  display: none;
}

.section-inner {
  margin-bottom: 20px;
}