%player {
  position: absolute;
  width: 15px;
  height: 15px;
  background-size: 15px auto;
  background-position: 0 0;
  background-repeat: no-repeat;
}

%player_current {
  height: 35px;
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 20px;
    padding-top: 15px;
    background-image: url(../img/formation/items/arrow.png);
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 15px auto;
  }
}

%name {
  width: 60px;
  height: 23px;
  background-size: 60px auto;
}

.field {
  position: relative;
  width: 300px;
  height: 200px;
  margin: 0 auto;
  background-image: url(../img/formation/items/field.jpg);
  background-size: 300px auto;
  background-repeat: no-repeat;

  .g {
    @extend %player;
    background-image: url(../img/formation/items/g.png);
    // background-image: url(../img/formation/items/g_current.png);
    top: 94px;
    left: 20px;
    &.is-current {
      @extend %player_current;
      background-image: url(../img/formation/items/g_current.png);
    }
  }
  .d {
    @extend %player;
    background-image: url(../img/formation/items/d.png);
    // background-image: url(../img/formation/items/d_current.png);
    &.is-current {
      @extend %player_current;
      background-image: url(../img/formation/items/d_current.png);
    }
  }
  .m {
    @extend %player;
    background-image: url(../img/formation/items/m.png);
    // background-image: url(../img/formation/items/m_current.png);

    &.is-current {
      @extend %player_current;
      background-image: url(../img/formation/items/m_current.png);
    }
  }
  .f {
    @extend %player;
    // background-image: url(../img/formation/items/f.png);
    background-image: url(../img/formation/items/f_current.png);
    &.is-current {
      @extend %player_current;
      background-image: url(../img/formation/items/f_current.png);
    }
  }

  $values: 23,1,12,14,3,8,18,4,6,16,17,2,13,22,5,15,7,20,11,10,19,21,9, else;
  @each $value in $values {
    .p_#{$value}{
      @extend %name;
      background-image: url(../img/player/#{$value}.png) !important;
      &.g {
        transform: translate3d(-25px,-5px,0);
      }
      &.d {
        transform: translate3d(-10px,-5px,0);
      }
      &.m {
        transform: translate3d(-5px,-5px,0);
      }
      &.f {
        transform: translate3d(-10px,-5px,0);
      }
    }
  }
  .section-343 & {
    // background-image: url(../img/formation/343_y.png);
    .d1 {
      top: 43px;
      left: 58px;
    }
    .d2 {
      top: 94px;
      left: 58px;
    }
    .d3 {
      top: 145px;
      left: 58px;
    }
    .m1 {
      top: 18px;
      left: 133px;
    }
    .m2 {
      top: 67px;
      left: 110px;
    }
    .m3 {
      top: 126px;
      left: 110px;
    }
    .m4 {
      top: 172px;
      left: 133px;
    }
    .f1 {
      top: 27px;
      left: 207px;
    }
    .f2 {
      top: 94px;
      left: 225px;
    }
    .f3 {
      top: 157px;
      left: 207px;
    }
  }


  .section-433 & {
    // background-image: url(../img/formation/433_y.png);
    .d1 {
      top: 24px;
      left: 70px;
    }
    .d2 {
      top: 76px;
      left: 63px;
    }
    .d3 {
      top: 112px;
      left: 63px;
    }
    .d4 {
      top: 163px;
      left: 70px;
    }
    .m1 {
      top: 34px;
      left: 135px;
    }
    .m2 {
      top: 94px;
      left: 117px;
    }
    .m3 {
      top: 147px;
      left: 135px;
    }
    .f1 {
      top: 36px;
      left: 212px;
    }
    .f2 {
      top: 94px;
      left: 187px;
    }
    .f3 {
      top: 147px;
      left: 212px;
    }
  }

  .section-3322 & {
    // background-image: url(../img/formation/3322_y.png);
    .d1 {
      top: 42px;
      left: 62px;
    }
    .d2 {
      top: 95px;
      left: 62px;
    }
    .d3 {
      top: 145px;
      left: 62px;
    }
    .m1 {
      top: 20px;
      left: 124px;
    }
    .m2 {
      top: 94px;
      left: 124px;
    }
    .m3 {
      top: 163px;
      left: 124px;
    }
    .m4 {
      top: 50px;
      left: 157px;
    }
    .m5 {
      top: 138px;
      left: 157px;
    }
    .f1 {
      top: 78px;
      left: 202px;
    }
    .f2 {
      top: 113px;
      left: 202px;
    }
  }
}
