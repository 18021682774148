.list-formation {
  display: flex;
  margin: 20px 0;
  li {
    margin: 0 10px;
    a {
      display: block;
      text-decoration: none;
      color: #000;
      font-size: 13px;
      img {
        border: 3px solid transparent;
        box-sizing: border-box;
      }
      &.is-selected {
        img {
          border: 3px solid #75aadb;
        }
      }
    }
    span {
      display: block;
      margin: 0 0 15px 0;
      text-align: center;
      font-weight: bold;
    }
  }
}
